import React, { Component} from 'react';

import teach from 'assets/teach.svg';
import sherlock from 'assets/sherlock.svg';
import interact from 'assets/interact.svg';

import './Features.sass'

class Features extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  render() {
    return (
      <section class="section-primary">
        <div class="container">
          <h1 class="title is-2 has-text-centered has-text-primary-light">
            Discover PixelCities
          </h1>
          <h2 class="subtitle is-5 has-text-centered has-text-white">
            PixelCities builds the playing field for multistakeholder collaboration
          </h2>
          <br />

        </div>

        <div class="container is-max-desktop">
          <div class="columns is-tablet is-variable is-1-mobile is-0-tablet is-8-desktop is-8-widescreen">
            <div class="column col-block has-text-centered is-third">
              <figure class="image is-256x256 is-inline-block">
                <img src={teach} alt="" />
              </figure>

              <div class="txt-block">
                <h1 class="title is-size-5 has-text-primary-light py-2">
                  ROBUST
                </h1>

                <p class="subtitle is-size-6 has-text-white">
                  Trust that your results will be stable and reliable
                </p>

              </div>
            </div>


            <div class="column col-block has-text-centered is-third">
              <figure class="image is-256x256 is-inline-block">
                <img src={interact} alt="" />
              </figure>

              <div class="txt-block">
                <h1 class="title is-size-5 has-text-primary-light py-2">
                  HARMONIOUS
                </h1>

                <p class="subtitle is-size-6 has-text-white">
                  Ensure that you can utilise and integrate different classification systems
                </p>

              </div>
            </div>


            <div class="column col-block has-text-centered is-third">
              <figure class="image is-256x256 is-inline-block">
                <img src={sherlock} alt="" />
              </figure>

              <div class="txt-block">
                <h1 class="title is-size-5 has-text-primary-light py-2">
                  COMPLIANT
                </h1>

                <p class="subtitle is-size-6 has-text-white">
                  Retain control and have peace of mind that private data stays that way
                </p>

              </div>
            </div>

          </div>
        </div>

        <br /> <br /> <br />
      </section>

    )
  }
}

export default Features;
