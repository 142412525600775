import React, { Component } from 'react';

import Card from 'components/Card';

import image from 'assets/cities-utilities.jpg';

class CitiesUtilitiesCard extends Component {
  render() {
    return (
      <Card
        image={image}
        title="Cities: Utilities Consumption"
        href="/cases/cities-utilities-consumption"
      />
    )
  }
}

export default CitiesUtilitiesCard;
