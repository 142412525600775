import React, { Component } from 'react';
import { Route } from "react-router-dom";

import Hero from './Hero';
import Introduction from './Introduction';
import Features from './Features';
import DataTags from './DataTags';

import UseCases from './UseCases';
import Breakdown from './Breakdown';
import YellowCTA from './YellowCTA';

class HomeRoute extends Component {
  parentPath = this.props.match.path;

  render() {
    return (
      <div>
        <Route path={this.parentPath} component={Home} />
      </div>
    )
  }
}


class Home extends Component {

  render() {
    return (
      <div>
        <Hero />
        <Introduction />
        <Breakdown />
        <Features />
        <UseCases />
        <YellowCTA />
      </div>
    );
  }
}

export default HomeRoute;
