import React, { Component} from 'react';

import backdropbird from 'assets/footer.svg'
import logo from 'assets/logo-yellow.svg';

import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <footer class="footer-no-padding">
        <div class="anchor" id="contact"></div>
        <div class="pl-6">
            <img src={backdropbird} alt="" width="128"/>
        </div>

        <div id="background">
          <div class="container py-6">
            <div class="columns is-centered px-6 is-vcentered" id="no-px-mobile">

              <div class="column is-three-fifths ml-6">
                <h1 class="title has-text-left">
                  <img src={logo} width="320" height="80" alt="PIXELCITIES"></img>
                </h1>

                <p class="subtitle has-text-left has-text-primary-light is-size-6 mr-6">
                  PixelCities builds data ecosystems for sustainable development through the aggregation of relevant knowledge into multi stakeholder data pools
                </p>
              </div>

              <div class="column is-one-third mx-6">
                <h1 class="title has-text-right has-text-left-mobile has-text-primary-light is-size-4">
                  Contact
                </h1>

                <p class="subtitle has-text-right has-text-left-mobile has-text-primary-light is-size-6">
                  hello@pixelcities.io <br/>
                </p>
              </div>
            </div>

          </div>

          <br />
        </div>
      </footer>
    )
  }
}

export default Footer;
