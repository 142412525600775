import React, { Component } from 'react';
import { Link, Route } from "react-router-dom";

import Section from 'components/Section';
import Navbar from 'components/Navbar';

class ConsultingRoute extends Component {
  parentPath = this.props.match.path;

  render() {
    return (
      <div>
        <Navbar transparent={false} />

        <Route path={this.parentPath} component={Consulting} />
      </div>
    )
  }
}


class Consulting extends Component {

  render() {
    return (
      <Section>
        <div class="container is-max-desktop">
          <h1 class="title is-2 pb-5">
            End-to-end consulting services
          </h1>

          <h2 class="subtitle is-4 pb-5">
            PixelCities offers a broad range of services focussing on data ecosystems and providing data driven solutions
          </h2>

          <div class="tile is-ancestor has-text-left py-7">
            <div class="tile is-vertical is-parent my-3 mx-3">
              <h3 class="title is-3 pb-4"> Data Strategy </h3>

              <div class="tile is-child div is-11-desktop">
                <h4 class="title is-4"> Business Value Roadmap </h4>
                <p>
                  Create a prioritized list of activities aligned with business priorities. This does not only include data and analytic infrastructure, but also organisational considerations in order to bring together technology solutions and business initiatives.
                </p>
              </div>

              <div class="tile is-child div is-11-desktop">
                <h4 class="title is-4"> Ecosystem Architecture </h4>
                <p>
                  Map out your analytics architecture in its current state, or plan a new architecture based on the needs of your organisation. In addition to our plug and play data ecosystem solution, we can offer tailored mixes of technological solutions to meet the analytical requirements and deliver data driven insights.
                </p>
              </div>
            </div>

            <div class="tile is-parent is-vertical my-3 mx-3">
              <h3 class="title is-3 pb-4"> Data Engineering </h3>

              <div class="tile is-child div is-11-desktop">
                <h4 class="title is-4"> Data Integration </h4>
                <p>
                  Map out and integrate your data required to meet business requirements using the appropriate services to create the perfect data ecosystem. This ensures your data can be utilized at a comfortable scale, which grows together with your data needs.
                </p>
              </div>

              <div class="tile is-child div is-11-desktop">
                <h4 class="title is-4"> Data Security & Privacy </h4>
                <p>
                  Keep your sensitive data private through the use of secure technological architecture, paired with identification, authentication, and protection through encryption, data synthesis, and differential privacy.
                </p>
              </div>

            </div>
          </div>
        </div>

        <section class="container is-max-desktop">
          <div class="columns is-mobile">
            <div class="column is-variable is-offset-8-desktop is-4-desktop is-offset-6-touch is-6-touch">
              <Link to="/contact">
                <div class="buttons is-right pt-5">
                  <button class="button is-medium is-fullwidth is-primary"> Get in touch </button>
                </div>
              </Link>
            </div>
          </div>
        </section>

      </Section>
    );
  }
}

export default ConsultingRoute;
