import React, { Component } from 'react';

import Card from 'components/Card';

import image from 'assets/consumer-protection.jpg';

class ConsumerProtectionCard extends Component {
  render() {
    return (
      <Card
        image={image}
        title="Consumer Protection"
        href="/cases/consumer-protection"
      />
    )
  }
}

export default ConsumerProtectionCard;
