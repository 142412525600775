import React, { FC } from 'react';

import dashboard from 'assets/dashboard.svg';

const ProductHero: FC = (props) => {
  return (
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered is-vcentered px-6" id="no-px-mobile">

            <div class="column is-narrow pl-6" id="no-px-mobile">
              <h2 class="subtitle is-3 has-text-left">
                We enable multiple organisations <br />
                to share data securely <br />
                and create comprehensive dashboards <br />
                for common insights
              </h2>
              <br></br>

              <a href="https://datagarden.app">
                <div class="buttons is-left pt-5">
                  <button class="button is-medium is-fullwidth is-primary"> Try it now </button>
                </div>
              </a>
            </div>

            <div class="column is-two-fifths is-hidden-touch">
              <figure class="image is-256x256 has-image-centered">
                <img src={dashboard} alt="" />
              </figure>
            </div>
          </div>

        </div>
      </div>

    </section>
  )
}

export default ProductHero;
