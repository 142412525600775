import React, { FC, useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlack, faJira, faGoogleDrive, faTrello } from '@fortawesome/free-brands-svg-icons'

import LaptopFrame from 'components/LaptopFrame';

import pipelineScreenshot from 'assets/pipeline-builder.png';
import brochureScreenshot from 'assets/brochure-editor.png';

const ProductFeatures: FC = (props) => {
  return (
    <div>
      <section class="container is-max-desktop">
        <div class="divider" />
        <h1 class="title py-6 is-2 has-text-centered">
          Scaling data driven multistakeholder collaborations
        </h1>
      </section>

      <section class="container is-max-desktop">
        <div class="columns is-centered is-vcentered px-3 py-7" id="no-px-mobile">
          <div class="column is-two-thirds is-hidden-tablet">
            <LaptopFrame image={pipelineScreenshot} />
          </div>

          <div class="column is-third ml-3 has-text-centered-mobile py-6" >
            <h1 class="title is-size-3">
              Visual Interface
            </h1>

            <p class="subtitle is-size-4">
              Enables non technical analysts to share data and build dashboards
            </p>
          </div>

          <div class="column is-two-thirds is-hidden-mobile">
            <LaptopFrame image={pipelineScreenshot} />
          </div>
        </div>
      </section>

      <section class="container is-max-desktop">
        <div class="columns is-centered is-vcentered px-3 py-7" id="no-px-mobile">

          <div class="column is-three-fifths">
            <div class="columns is-multiline is-mobile">

              <div class="column is-half has-text-centered">
                <div class="div is-6 is-pulled-right">
                  <figure class="icon is-medium">
                    <FontAwesomeIcon icon={faSlack} size="3x"/>
                  </figure>
                  <p class="subtitle is-size-6-mobile">
                    Slack
                  </p>
                </div>
              </div>

              <div class="column is-half has-text-centered">
                <div class="div is-6 is-pulled-left">
                  <figure class="icon is-medium">
                    <FontAwesomeIcon icon={faJira} size="3x"/>
                  </figure>
                  <p class="subtitle is-size-6-mobile">
                    Jira
                  </p>
                </div>
              </div>

              <div class="column is-half has-text-centered">
                <div class="div is-6 is-pulled-right">
                  <figure class="icon is-medium">
                    <FontAwesomeIcon icon={faGoogleDrive} size="3x"/>
                  </figure>
                  <p class="subtitle is-size-6-mobile">
                    Google<br/>Drive
                  </p>
                </div>
              </div>

              <div class="column is-half has-text-centered">
                <div class="div is-6 is-pulled-left">
                  <figure class="icon is-medium">
                    <FontAwesomeIcon icon={faTrello} size="3x"/>
                  </figure>
                  <p class="subtitle is-size-6-mobile">
                    Trello
                  </p>
                </div>
              </div>

            </div>
          </div>

          <div class="column is-thirds ml-3 has-text-centered-mobile py-6" id="no-px-mobile">
            <h1 class="title is-size-3">
              Team Management
            </h1>

            <p class="subtitle is-size-4">
              Facilitates project management across multiple organisations
            </p>
          </div>
        </div>
      </section>

      <section class="container is-max-desktop">
        <div class="columns is-centered is-vcentered px-3 py-7" id="no-px-mobile">

          <div class="column is-two-thirds is-hidden-tablet">
            <LaptopFrame image={brochureScreenshot} />
          </div>

          <div class="column is-third ml-3 has-text-centered-mobile py-6" id="no-px-mobile">
            <h1 class="title is-size-3">
              End to End
            </h1>

            <p class="subtitle is-size-4">
              Produces a communicable result for ease of dissemination
            </p>
          </div>

          <div class="column is-two-thirds is-hidden-mobile">
            <LaptopFrame image={brochureScreenshot} />
          </div>
        </div>
      </section>

      <section class="container is-max-desktop">
        <div class="columns is-mobile">
          <div class="column is-variable is-offset-8-desktop is-4-desktop is-offset-6-touch is-6-touch">
            <Link to="/contact?demo=1">
              <div class="buttons is-right pt-5">
                <button class="button is-medium is-fullwidth is-primary"> Get a demo </button>
              </div>
            </Link>
          </div>
        </div>

      </section>
    </div>
  )
}

export default ProductFeatures;
