import React, { FC, useRef, useEffect, useState } from 'react';

import './Section.sass';

const Section: FC = (props) => {
  const targetRef = useRef();
  const [height, setDimensions] = useState(0);

  useEffect(() => {
    if (targetRef.current) {
      setDimensions(targetRef.current.offsetHeight)
    }
  }, []);

  return (
    <section ref={targetRef} class="section-fullheight" style={ props.backdrop && height > window.innerHeight ? { marginBottom: 85 } : {}}>
      {props.children}
    </section>
  )
}

Section.defaultProps = {
  backdrop: true
}

export default Section;
