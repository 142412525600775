import React, { Component } from 'react';

import Section from 'components/Section';
import TitleBox from 'components/TitleBox';
import PrevNextPost from 'components/PrevNextPost';

import background from 'assets/cities-utilities.jpg';

class CitiesUtilitiesLayout extends Component {
  render() {
    return (
      <div>
        <TitleBox
          image={background}
          title="Cities: Utilities Consumption"
          subtitle="Cities do not always have consolidated information about utilities consumption due to data silos"
        />

        <Section>
          <div class="container is-max-desktop" id="no-px-mobile">
            <div class="columns is-centered is-mobile">
              <div class="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop">
                <div class="content has-text-justified px-1">

                  <h2>Problem</h2>

                  <p>Municipalities wish to track whether and how various neighbourhoods are meeting targets concerning water consumption, waste generation or electricity usage, yet the data lives within various independent companies operating in parallel around the region. To further complicate matters, data at the meter level is private, and cannot be shared without each individual household giving consent. Raising awareness about this problem, and developing processes to gather consent from households is costly and complicated. Municipalities therefore are unable to implement data driven policies to improve service delivery to citizens.</p>
                  <h2>Solution</h2>
                  <p>Utilities companies can combine their datasets and provide neighbourhood level averages of electricity consumption aggregated to a quarterly figure. This is a sufficient level of granularity to derive policy but insufficient to link to households or companies.</p>

                  <p>Each utility company utilises their own private space within the DataGarden to process and prepare this data, and then share it to the common dashboard which is viewable by decision makers in the municipality. </p>

                  <p>The municipality may choose to make a second dashboard available for viewing by the general public, indicating progress at the municipal level on targets for improved service delivery and reduced resource consumption.</p>

                  <h2>Outcome</h2>
                  <ul>
                    <li>The municipality is able to monitor progress on targets</li>
                    <li>The municipality is enabled to design data drive incentives and policies</li>
                    <li>Resource consumption is reduced in the region</li>
                    <li>Delivery of public services is improved</li>
                  </ul>

                  <h2>Real Life Examples</h2>
                  <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.circularcityfundingguide.eu/case-studies/wcycle-institute-re-thinking-the-business-model-of-maribor/">Maribor</a> Utilities Holding</li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.intelligentcitieschallenge.eu/sites/default/files/2019-07/Assesment_Report_RIJEKA.pdf">Rijeka</a> Open Data Challenge</li>
                  </ul>

                </div>

                <br/><br/>
                <PrevNextPost
                  prevHref="/cases/health-accelerated-research"
                  prevTitle="Health: Accelerated Research"
                  nextHref="/cases/sustainability-data-aggregation"
                  nextTitle="Sustainability Data Aggregation"
                />

              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default CitiesUtilitiesLayout;

