import React, { Component } from 'react';

import Card from 'components/Card';

import image from 'assets/cities-maintenance.jpg';

class CitiesMaintenanceCard extends Component {
  render() {
    return (
      <Card
        image={image}
        title="Cities: Coordinated Maintenance"
        href="/cases/cities-maintenance"
      />
    )
  }
}

export default CitiesMaintenanceCard;
