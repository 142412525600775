import React, { Component } from 'react';

import Card from 'components/Card';

import datapool from 'assets/datapool-privacy.jpg';

class DataPoolsCard extends Component {
  render() {
    return (
      <Card
        image={datapool}
        title="What are Data Pools?"
        href="/concepts/what-are-data-pools"
      />
    )
  }
}

export default DataPoolsCard;
