import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from 'utils/PrivateRoute';
import ScrollToTop from 'utils/ScrollToTop';

import Navbar from 'components/Navbar';
import Login from 'components/Login';
import Footer from 'components/Footer';

import Home from 'pages/Home';
import Consulting from 'pages/Consulting';
import Product from 'pages/Product';
import Concepts from 'pages/Concepts';
import UseCases from 'pages/UseCases';
import Contact from 'pages/Contact';
import About from 'pages/About';

function App() {
  return (
    <Router basename="/">
      <ScrollToTop />

      <div className="App">

        <Switch>
          <Route path="/login">
            <Navbar transparent={false} />
            <Login />
          </Route>

          <PrivateRoute path="/explore" isAuth={false} component={Login} />
          <PrivateRoute path="/concepts/what-is-data-encryption" isAuth={false} component={Login} />
          <PrivateRoute path="/concepts/what-is-synthetic-data" isAuth={false} component={Login} />
          <PrivateRoute path="/concepts/what-is-differential-privacy" isAuth={false} component={Login} />

          <Route path="/consulting" component={Consulting} />
          <Route path="/product" component={Product} />
          <Route path="/concepts" component={Concepts} />
          <Route path="/cases" component={UseCases} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/" component={Home} />
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
