import React, { Component } from 'react';

import Section from 'components/Section';
import TitleBox from 'components/TitleBox';
import PrevNextPost from 'components/PrevNextPost';

import background from 'assets/cities-maintenance.jpg';

class CitiesMaintenanceLayout extends Component {
  render() {
    return (
      <div>
        <TitleBox
          image={background}
          title="Cities: Coordinated Maintenance"
          subtitle="Cities are data rich environments yet citizens and local governments do not have structured, dynamic access to data"
        />

        <Section>
          <div class="container is-max-desktop" id="no-px-mobile">
            <div class="columns is-centered is-mobile">
              <div class="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop">
                <div class="content has-text-justified px-1">

                  <h2>Problem</h2>
                  <p>Municipalities manage and maintain infrastructure yet information about outages, planned or unplanned, lives within various independent departments operating in parallel. The deployment of teams onsite to implement fixes is inefficient and frustrating as incomplete information is available to stakeholders, meanwhile the losses from the damaged infrastructure keep accumulating. Citizens are also frustrated as they lack up to date information about scheduled maintenance in their area.</p>

                  <h2>Solution</h2>
                  <p>The city can coordinate the process of fixing either planned or emergency outages by providing a single place for stakeholders to check on the status of, in the case of water, pipes, valves, and shutdowns throughout the city.</p>
                  <p>This automated map and report can be taken into the field and utilised to identify exactly what needs to be serviced, with full information about the surrounding system. Logging activities and events into this map, allows a full list of affected addresses to be generated, which allows for quicker communication to households and citizens. </p>

                  <h2>Outcome</h2>
                  <ul>
                    <li>Citizens can conveniently check online to get up to date information about outages</li>
                    <li>The city saves time and money on communicating information to citizens</li>
                    <li>Maintenance teams can coordinate better to deploy fixes (quicker and cheaper)</li>
                    <li>Complete information about infrastructure allows for more holistic planning</li>
                  </ul>

                  <h2>Examples</h2>
                  <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.esri.com/about/newsroom/blog/seattle-water-shutdown-map/">Seattle Water Outage Map</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="http://www.seattle.gov/city-light/outages">Seattle Lights Map</a></li>
                  </ul>

                </div>

                <br/><br/>
                <PrevNextPost
                  prevHref="/cases/sustainability-data-aggregation"
                  prevTitle="Sustainability Data Aggregation"
                  nextHref="/cases/consumer-protection"
                  nextTitle="Consumer Protection"
                />

              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default CitiesMaintenanceLayout;

