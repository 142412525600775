import React, { Component } from 'react';

import Card from 'components/Card';

import image from 'assets/agriculture-accelerated-research.jpg';

class AgricultureResearchCard extends Component {
  render() {
    return (
      <Card
        image={image}
        title="Agriculture: Accelerated Research"
        href="/cases/agriculture-accelerated-research"
      />
    )
  }
}

export default AgricultureResearchCard;
