import React, { FC } from 'react';

import './Blockquote.sass';

const Blockquote: FC = (props) => {
  console.log(props);
  return (
    <div class="container py-6">
      <div class="anchor" id={props.anchor}></div>
      <div class="columns is-centered is-vcentered px-7" id="no-px-mobile">

        <div class={"column mx-6 my-6" + (props.invert ? " is-hidden-tablet" : "")}>
          <figure class="image is-svg is-square">
            <img class="is-rounded" src={props.image} alt="" />
          </figure>
        </div>

        <div class={"column is-two-thirds " + (props.invert ? "blockquote-right" : "blockquote-left")} id="no-px-mobile">
          <div class="columns is-vcentered">
            <div class="column has-text-justified is-size-5 is-size-6-mobile">
              {props.children}
            </div>
          </div>
        </div>

        <div class={"column mx-6 my-6 hax-text-centered " + (props.invert ? "is-hidden-mobile" : "is-hidden")}>
          <figure class="image is-svg is-square">
            <img class="is-rounded" src={props.image} alt="" />
          </figure>
        </div>

      </div>
    </div>
  )
}

Blockquote.defaultProps = {
  anchor: "",
  image: "",
  invert: false
}

export default Blockquote;

