import React, { FC, useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUncharted } from '@fortawesome/free-brands-svg-icons'
import { faKey, faChartBar } from '@fortawesome/free-solid-svg-icons'

import Card from 'components/Card';

const ProductPrivacy: FC = (props) => {
  const mediaCss = {
    minHeight: "14rem"
  }

  return (
    <div>
      <section class="container py-7 is-max-desktop">
        <div class="divider" />
        <h1 class="title is-2 py-6 is-2 has-text-centered">
          Privacy as a Service
        </h1>

        <h2 class="subtitle is-4 is-size-5-mobile has-text-centered">
          We offer state of the art services and technology to enable privacy preserving data analysis
        </h2>
      </section>

      <div class="columns is-centered is-vcentered is-multiline is-variable is-1-mobile is-3-tablet is-3-desktop px-6" id="no-px-mobile">

        <div class="column is-3-desktop is-5-tablet">
          <Card
            title="Data Encryption"
            icon={faKey}
            customCss={mediaCss}
            subtitle="Data is end-to-end encrypted once you start using the platform. Results are safely computed within your own browser environment."
            href="/concepts/what-is-data-encryption"
            cta="Learn More"
          />
        </div>

        <div class="column is-3-desktop is-5-tablet">
          <Card
            title="Synthetic Data"
            icon={faUncharted}
            customCss={mediaCss}
            subtitle="Keep your data private, even from your collaborators."
            href="/concepts/what-is-synthetic-data"
            cta="Learn More"
          />
        </div>

        <div class="column is-3-desktop is-5-tablet">
          <Card
            title="Differential Privacy"
            icon={faChartBar}
            customCss={mediaCss}
            subtitle="Ensure that published data does not accidentally reveal personal information."
            href="/concepts/what-is-differential-privacy"
            cta="Learn More"
          />
        </div>

      </div>

      <section class="container is-max-desktop">
        <div class="columns is-mobile">
          <div class="column is-variable is-offset-8-desktop is-4-desktop is-offset-6-touch is-6-touch">
            <Link to="/contact">
              <div class="buttons is-right pt-5">
                <button class="button is-medium is-fullwidth is-primary"> Any questions? Contact us </button>
              </div>
            </Link>
          </div>
        </div>
      </section>

    </div>
  )
}

export default ProductPrivacy;
