import React, { Component} from 'react';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

import Navbar from 'components/Navbar';

import logo from 'assets/logo-yellow.svg'

class Hero extends Component {
  render() {
    return (
      <section class="hero-with-bg is-primary is-fullheight">
        <Helmet>
            <meta charSet="utf-8" />
            <title>PixelCities</title>
            <meta name="description" content="PixelCities builds data ecosystems for sustainable development through the aggregation of relevant knowledge into multi stakeholder data pools" />
        </Helmet>

        <div class="hero-head">
          <Navbar transparent={true} />
        </div>

        <div class="hero-body">
          <div class="container">
              <div>
                <br /><br /><br />
                <h1 class="title is-1 has-text-centered">
                  <img src={logo} width="800" height="200" alt="PIXELCITIES"></img>
                </h1>
                <br />

                <h2 class="subtitle is-3 has-text-centered">
                  Solve <span class="has-text-weight-bold has-text-primary-light"> common </span> problems <br />
                  with <span class="has-text-weight-bold has-text-primary-light"> collective </span> efficiency <br />
                  through <span class="has-text-weight-bold has-text-primary-light"> data </span> collaboratives
                </h2>
                <br></br>

                <div class="buttons is-centered">
                  <a href="https://datagarden.app">
                    <button class="button is-medium is-light is-outlined"> Find out more </button>
                  </a>
                  <Link to="/contact?demo=1">
                    <button class="button is-medium is-primary is-inverted"> Get a demo </button>
                  </Link>
                </div>

            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Hero;
