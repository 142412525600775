import React, { FC, Component } from 'react';
import { Switch, Route } from "react-router-dom";

import Section from 'components/Section';
import Navbar from 'components/Navbar';

import { HealthLayout, HealthCard } from './Health';
import { CitiesUtilitiesLayout, CitiesUtilitiesCard } from './CitiesUtilities';
import { SustainabilityDataLayout, SustainabilityDataCard } from './SustainabilityData';
import { CitiesMaintenanceLayout, CitiesMaintenanceCard } from './CitiesMaintenance';
import { ConsumerProtectionLayout, ConsumerProtectionCard } from './ConsumerProtection';
import { AgricultureResearchLayout, AgricultureResearchCard } from './AgricultureResearch';

class UseCasesRoute extends Component {
  parentPath = this.props.match.path;

  render() {
    return (
      <div>
        <Navbar transparent={false} />

        <Switch>
          <Route path={this.parentPath + "/cities-utilities-consumption"} component={CitiesUtilitiesLayout} />
          <Route path={this.parentPath + "/health-accelerated-research"} component={HealthLayout} />
          <Route path={this.parentPath + "/sustainability-data-aggregation"} component={SustainabilityDataLayout} />
          <Route path={this.parentPath + "/cities-maintenance"} component={CitiesMaintenanceLayout} />
          <Route path={this.parentPath + "/consumer-protection"} component={ConsumerProtectionLayout} />
          <Route path={this.parentPath + "/agriculture-accelerated-research"} component={AgricultureResearchLayout} />
          <Route path={this.parentPath} component={UseCasesLayout} />
        </Switch>
      </div>
    )
  }
}

const UseCasesLayout: FC = () => {
  return (
    <Section>
      <div class="container">
        <h1 class="title is-2 pb-5">
          Use cases
        </h1>

        <h2 class="subtitle is-4 pb-5">
          Explore relevant use cases about data driven decision making and data sharing solutions.
        </h2>

        <div class="columns is-centered is-vcentered is-multiline is-variable is-1-mobile is-1-tablet is-3-desktop px-6" id="no-px-mobile">

          <div class="column is-4">
            <HealthCard />
          </div>

          <div class="column is-4">
            <CitiesUtilitiesCard />
          </div>

          <div class="column is-4">
            <SustainabilityDataCard />
          </div>

          <div class="column is-4">
            <CitiesMaintenanceCard />
          </div>

          <div class="column is-4">
            <ConsumerProtectionCard />
          </div>

          <div class="column is-4">
            <AgricultureResearchCard />
          </div>

        </div>
      </div>
    </Section>
  );
}

export default UseCasesRoute;
