import React, { Component } from 'react';

import Section from 'components/Section';
import TitleBox from 'components/TitleBox';
import PrevNextPost from 'components/PrevNextPost';

import background from 'assets/agriculture-accelerated-research.jpg';

class AgricultureResearchLayout extends Component {
  render() {
    return (
      <div>
        <TitleBox
          image={background}
          title="Agriculture: Accelerated Research"
          subtitle="The uptake of digital technologies and data analysis to improve agricultural yields and monitor the health of the soil is increasing"
        />

        <Section>
          <div class="container is-max-desktop" id="no-px-mobile">
            <div class="columns is-centered is-mobile">
              <div class="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop">
                <div class="content has-text-justified px-1">

                  <h2>Problem</h2>
                  <p>An increasing number of farmers and agri-tech companies are working with precision agriculture to optimise production and ensure sustainability of their land.</p>
                  <p>Farmers who are starting out with this, have to start from scratch and are not able to leverage insights from similar plots that have done the same. </p>
                  <p>Further, national governments and multilateral organisations are interested to monitor the health of land and soil, with the aim to conserve and restore ecosystems. Allocating funding and resources into regions should be done optimally - ie. where the biggest positive environmental and social impact can be seen. However aggregated data about land health is not available to decision makers at scale. </p>

                  <h2>Solution</h2>
                  <p>Multinational agricultural support organisations (logistics operators and fertilizer companies) who already maintain relationships with the majority of productive farms globally can, in partnership with a trusted data intermediary and agritech product owners and companies, create a centralized dataset of land health and characteristics. </p>
                  <p>This dataset can be utilised by researchers and innovation managers working in the wider Agricultural value chain to develop new services and offers the opportunity for the whole healthcare industry to progress on a comprehensive database.</p>

                  <h2>Outcome</h2>
                  <ul>
                    <li>Insights into land usage and health at a granular level to inform conservation and restoration policies</li>
                    <li>Comprehensive insights into the market landscape of agritech and its benefits for the economy and the environment</li>
                    <li>Improve land management and agricultural sector overall</li>
                  </ul>

                </div>
                <br />
                <div class="content no-wrap no-block is-small px-1">
                  <h3> REFERENCES </h3>
                  <p><a target="_blank" rel="noopener noreferrer" href="https://www.talend.com/resources/big-data-agriculture/">https://www.talend.com/resources/big-data-agriculture/</a></p>
                  <p><a target="_blank" rel="noopener noreferrer" href="https://fsr.eui.eu/wp-content/uploads/Can-Atik-Presentation-EUI-Updated-Version.pdf">https://fsr.eui.eu/wp-content/uploads/Can-Atik-Presentation-EUI-Updated-Version.pdf</a></p>
                  <p><a target="_blank" rel="noopener noreferrer" href="https://timesofindia.indiatimes.com/blogs/et-commentary/agriculture-sector-scale-up-your-farms-pool-in/">https://timesofindia.indiatimes.com/blogs/et-commentary/agriculture-sector-scale-up-your-farms-pool-in/</a></p>
                </div>

                <br/><br/>
                <PrevNextPost
                  prevHref="/cases/consumer-protection"
                  prevTitle="Consumer Protection"
                />

              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default AgricultureResearchLayout;

