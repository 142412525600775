import React, { Component } from 'react';

import Card from 'components/Card';

import health from 'assets/health-research.jpg';

class HealthCard extends Component {
  render() {
    return (
      <Card
        image={health}
        title="Health: Accelerated Research"
        href="/cases/health-accelerated-research"
      />
    )
  }
}

export default HealthCard;
