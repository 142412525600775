import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';

import Section from 'components/Section';
import Navbar from 'components/Navbar';

const CONTACT_FORM_API_ENDPOINT = "https://contact.api.pixelcities.io/contact"
const CONTACT_FORM_API_KEY = process.env.REACT_APP_CONTACT_FORM_API_KEY;


class ContactRoute extends Component {
  parentPath = this.props.match.path;

  render() {
    return (
      <div>
        <Navbar transparent={false} />

        <Route path={this.parentPath} component={Contact} />
      </div>
    )
  }
}


class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      error: false,
      success: false,
      requiredName: false,
      requiredEmail: false,
      name: "",
      email: "",
      message: ""
    }
  }

  queryParams = new URLSearchParams(this.props.location.search);
  isDemo = this.queryParams.get("demo") === "true" || this.queryParams.get("demo") === "1";

  handleSubmit = (e) => {
    e.preventDefault();

    // very simple form validation, not super important
    if (this.state.name === "" || this.state.email === "") {
      this.setState({
        requiredName: this.state.name === "",
        requiredEmail: this.state.email === ""
      })

    } else {
      this.setState({requiredName: false, requiredEmail: false})

      axios.post(CONTACT_FORM_API_ENDPOINT, {
        name: this.state.name,
        email: this.state.email,
        message: this.state.message,
        demo: this.isDemo
      }, {
        headers: {
          "content-type": "application/json",
          "x-api-key": CONTACT_FORM_API_KEY
        }
      }).then((response) => {
        this.setState({
          disabled: true,
          success: true
        });
      }).catch((e) => {
        this.setState({error: true});
      })
    }
  }

  handleNameChange = (e) => {
    this.setState({name: e.target.value});
  }

  handleEmailChange = (e) => {
    this.setState({email: e.target.value});
  }

  handleMessageChange = (e) => {
    this.setState({message: e.target.value});
  }

  handleErrorClose = (e) => {
    this.setState({error: false});
  };

  handleSuccessClose = (e) => {
    this.setState({success: false});
  };

  render() {
    return (
      <Section backdrop={true}>
        <div class="container">

          { this.state.error ?
            <article class="message is-danger">
              <div class="message-header">
                <p>Something went wrong</p>
                <button class="delete" aria-label="delete" onClick={this.handleErrorClose} />
              </div>
            </article>
          : null }

          { this.state.success ?
            <article class="message is-success">
              <div class="message-header">
                <p>We will be in touch shortly!</p>
                <button class="delete" aria-label="delete" onClick={this.handleSuccessClose} />
              </div>
            </article>
          : null }

          <div class="columns is-centered">
            <div class="column is-two-thirds">

              <h1 class="title is-2 pb-5">
                { this.isDemo ?
                  "Request a demo" :
                  "Ask us anything"
                }
              </h1>
              <h2 class="subtitle is-4 pb-5">
                { this.isDemo ?
                  "Please fill in the form and we will get back to you as soon as possible" :
                  "Let’s chat about your data collaborative"
                }
              </h2>

              <form class="pt-6" onSubmit={this.handleSubmit}>
                <div class="field">
                  <label class="label">Name </label>
                  <div class="control">
                    <input class="input" type="text" placeholder="" onChange={this.handleNameChange}/>
                  </div>
                  { this.state.requiredName ?
                    ( <p class="help is-danger"> Please enter your name </p> ) :
                    null
                  }
                </div>

                <div class="field">
                  <label class="label">Email </label>
                  <div class="control has-icons-left">
                    <input class="input" type="email" onChange={this.handleEmailChange} />
                    <span class="icon is-small is-left">
                      <FontAwesomeIcon icon={faEnvelope} size="xs"/>
                    </span>
                  </div>
                  { this.state.requiredEmail ?
                    ( <p class="help is-danger"> Please enter your email </p> ) :
                    null
                  }
                </div>

                <div class="field">
                  <label class="label">Message</label>
                  <div class="control">
                    <textarea
                      class="textarea"
                      placeholder={ this.isDemo ?
                        "Hi there, do you need a plug and play solution for your data collaborative? Get in touch and we will get back to you within 24 hours to schedule a discussion about your needs and accompanying demo." :
                        "Hi there, how can we help you with your data collaborative? Talk to us about your situation and we will be happy to help you, connect you to relevant resources, or schedule a video call."
                      }
                      onChange={this.handleMessageChange}>
                    </textarea>
                  </div>
                </div>

                <div class="field is-grouped is-grouped-right">
                  <div class="control">
                    <button class="button is-primary" disabled={this.state.disabled} >Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </Section>
    );
  }
}

export default ContactRoute;
