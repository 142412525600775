import React, { Component } from 'react';

import Card from 'components/Card';

import image from 'assets/sustainability-data-aggregation.jpg';

class SustainabilityDataCard extends Component {
  render() {
    return (
      <Card
        image={image}
        title="Sustainability Data Aggregation (ESG)"
        href="/cases/sustainability-data-aggregation"
      />
    )
  }
}

export default SustainabilityDataCard;
