import React, { Component} from 'react';
import { Link } from "react-router-dom";

import VerticalTabs from 'components/VerticalTabs';

class UseCases extends Component {
  render() {
    return (
      <section class="section">
        <div class="container is-fullhd">
          <h1 id="use-cases" class="title is-size-2 has-text-centered py-5">
            Use Cases
          </h1>

          <p class="subtitle is-size-5 has-text-black px-7">
            Data driven decision making is all very well but how do you know you've got a comprehensive evidence base? If you are making decisions that extend past the boundaries of your own organisation, you will benefit from the creation of a common dashboard for insights.
          </p>

          <br /><br />

          <VerticalTabs>
            <div>
              <h1> Healthcare </h1>
              <h2> Co-own and develop an environment to share anonymised data into a common pool </h2>
              <section class="content has-text-justified">
                <h3> Health: Accelerated Research  </h3>
                <p> Ethics around sharing health data are complex and still evolving. Pooling results from disaggregated clinical trials with a trusted data intermediary can help create representative datasets for analysis. Such datasets can enable more accurate predictive models and increase efficiencies in drug discovery, and data-driven learnings for increased efficacy of interventions. </p>

                <p>Covid has accelerated and deepened the thinking about such intermediaries, but the healthcare industry already cites multiple examples of start-ups and scale-ups, as well as government funded research projects working on resolving some of the more technical and confidentiality related aspects of sharing health data. </p>

              </section>
              <nav><Link className="subtitle is-5" to="/cases/health-accelerated-research" >Read More ... </Link></nav>
            </div>

            <div>
              <h1> Sustainability </h1>
              <h2> Organisations that gather ESG data often use cumbersome and error prone tools </h2>
              <section class="content">
                <h3>Sustainability Data Aggregation (ESG)</h3>
                <p>Data about sustainability is very handy to be able to derive relevant strategies and monitor progress towards the sustainable development goals and similar targets. Yet it's a new field to capture, organise, and process this data for analysis and decision making.</p>
                <p>Agencies and organizations that gather ESG data and assess ESG related risks use surveys, spreadsheets and other cumbersome and error prone tools, and then have to process these manually into reports and systems, which other ESG agencies may not access. Whilst ESG agencies compete for 'market information share', some companies may be asked to complete such forms and surveys more than once, as data is not easily shareable across agencies. </p>
                <p>Setting up a centralized dashboard may support the creation of a more comprehensive dataset of ESG factors. Each ESG agency can utilise a form (and fields) of their choosing, and a taxonomy can be centrally managed that integrates and harmonises data.</p>
              </section>
              <nav><Link className="subtitle is-5" to="/cases/sustainability-data-aggregation" >Read More ... </Link></nav>
            </div>

            <div>
              <h1> Utilities </h1>
              <h2> Cities do not always have consolidated information about utilities consumption due to data silos </h2>
              <section class="content">
                <h3> Cities: Utilities Consumption </h3>
                <p>Cities house 80% of the world's population, and are tasked with delivering services to their citizens efficiently, and at the same time are increasingly considering how to do so whilst keeping emissions low.</p>
                <p>Deriving policies about consumption and emissions requires data at a sufficiently granular level (for example city, street, neighbourhood etc.) to inform new infrastructure or initiatives. Surprisingly, the city does not always have consolidated information about utilities consumption because of (information) systems that were developed in silos - either across departments or even across companies and operators who act independently in the city.</p>
                <p>Cities can drive the development of a common dashboard for utilities companies to share data aggregated to neighbourhood level on, for instance, a quarterly basis. Challenges are more likely to emerge from technical integration from different utility providers then from confidentiality issues. </p>
              </section>
              <nav><Link className="subtitle is-5" to="/cases/cities-utilities-consumption" >Read More ... </Link></nav>
            </div>
          </VerticalTabs>

        </div>
        <br /><br />
      </section>
    )
  }
}

export default UseCases;
