import React, { Component} from 'react';

import forest from 'assets/trees.webp';
import city from 'assets/city.webp';
import data from 'assets/data.webp';
import elevator from 'assets/elevator.webp';

import './DataTags.css';

class DataTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  masks = () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='0' height='0'>
        <clipPath id='doughnut-path'>
          <path d="M100 20c-44.181 0-80 35.819-80 80s35.819 80 80 80 80-35.819 80-80-35.819-80-80-80zm0 120.48c-22.351 0-40.48-18.128-40.48-40.48 0-22.351 18.128-40.479 40.48-40.479 22.351 0 40.479 18.128 40.479 40.479S122.351 140.48 100 140.48z"></path>
        </clipPath>

        <clipPath id='building-path'>
          <path d="M124.167 124.167V75.833H75.833V27.5H27.5v145h145v-48.333h-48.333z"></path>
        </clipPath>

        <clipPath id='heart-path'>
          <path d="M166.655 38.469c-9.299-7.81-18.359-9.983-30.39-9.969-12.771 1.106-23.826 7.345-36.266 23.11C87.56 35.845 76.505 29.606 63.734 28.5c-12.031-.014-21.092 2.159-30.391 9.969C27.331 43.52 20.5 56.93 20.5 69.71c.16 25.712 18.072 64.364 76.958 100.312l2.431 1.478.111-.066.111.066 2.431-1.478c58.886-35.948 76.798-74.6 76.958-100.312 0-12.78-6.832-26.19-12.845-31.241z"></path>
        </clipPath>

        <clipPath id='arrow-path'>
          <path d="M170 86.184L100 15.95 30 86.184h43.492v98.766h53.016V86.184H170z"></path>
        </clipPath>

      </svg>
    )
  }

  filters = () => {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='0' height='0'>
        <defs>
          <filter id="blue-filter" color-interpolation-filters="sRGB">
            <feComponentTransfer result="srcRGB"></feComponentTransfer>
            <feComponentTransfer><feFuncR type="linear" slope="0.8" intercept="0.1"></feFuncR>
            <feFuncG type="linear" slope="0.8" intercept="0.1"></feFuncG>
            <feFuncB type="linear" slope="0.8" intercept="0.1"></feFuncB></feComponentTransfer>
            <feColorMatrix type="saturate" values="0"></feColorMatrix>
            <feColorMatrix type="matrix" values="1 0 0 0 0 0.588235294117647 0 0 0 0.27058823529411763 -0.0862745098039216 0 0 0 0.8392156862745098 0 0 0 1 0"></feColorMatrix>
            <feComponentTransfer></feComponentTransfer>
          </filter>
        </defs>
      </svg>
    )
  }

  tetheredContent = (clipType, imgPath, titleLeft, titleRight, body) => {
    return (
      <div class="columns is-gapless is-centered is-vcentered has-text-centered-mobile pb-5">
        <div class="column is-narrow">
          <img id={clipType} src={imgPath} alt="" />
        </div>

        <div class="column is-hidden-tablet">
          <h2 class="subtitle has-text-weight-semibold is-size-4 mb-2" id="tether-item-parent">
            <div id="tether-item-mobile" />
            {titleLeft}<span class="is-size-4 has-text-primary"> your data </span>{titleRight}
            {titleLeft}<span class="is-size-4 has-text-primary"> your data </span>{titleRight}
          </h2>
        </div>

        <div class="column is-hidden-mobile is-one-third-fullhd has-text-left ">
          <h2 class="subtitle has-text-weight-semibold is-size-4 mr-2 has-text-left" id="tether-item-parent">
            <div id="tether-item" />
            {titleLeft}<span class="is-size-4 has-text-primary"> your data </span>{titleRight}
          </h2>

          <p class="content" id="tether-item-parent">
            {body}
          </p>
        </div>

        <div class="column is-narrow-fullhd is-one-third-fullhd">
          <h2 class="subtitle has-text-weight-semibold is-size-4">
            {titleLeft}<span class="is-size-4 has-text-primary"> your data </span>{titleRight}
          </h2>

          <p class="content">
            {body}
          </p>
        </div>
      </div>
    )
  }


  render() {
    return (
      <section class="section-primary-light">
        <div class="container">
          <h1 id="use-cases" class="title is-size-2 has-text-centered">
            Your Data, Your Rules
          </h1>
          <br />

        </div>

        { this.masks() }
        { this.filters() }

        <div class="columns is-centered">
          <div class="column is-two-thirds-tablet">

            {
              this.tetheredContent(
                "heart-clip",
                city,
                "Hook",
                "into the pool",
                "PixelCities integrates your framework to a common taxonomy automagically"
              )
            }

            {
              this.tetheredContent(
                "building-clip",
                data,
                "Manage",
                "sources",
                "PixelCities presents dataset specific rulesets to manage your data privacy and review other sources in the pool"
              )
            }

            {
              this.tetheredContent(
                "doughnut-clip",
                forest,
                "Contribute",
                "securely",
                "Pixelcities enables less tech savvy analysts to prepare data for the pool with a visual pipeline builder"
              )
            }

            {
              this.tetheredContent(
                "arrow-clip",
                elevator,
                "Enrich",
                "story",
                "PixelCities delivers integrated datasets and dashboards to enable data driven decision making"
              )
            }

          </div>
        </div>

        <br /> <br />
      </section>

    )
  }
}

export default DataTags;
