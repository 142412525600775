import React, { FC, Component } from 'react';


import './VerticalTabs.sass';

const TabHeader: FC = (props) => {
  return (
    <>
      <li role="button" class={"is-hidden-desktop" + (props.active ? " is-active" : "")} onClick={props.onClick}>
        <a class={"" + (props.active ? "has-text-primary" : "")}>
          { props.title }
        </a>
      </li>

      <div role="button" class={"vtab-block-multiline is-hidden-touch" + (props.active ? " is-active" : "")} onClick={props.onClick}>
        <h1 class={"title is-size-4 pt-1" + (props.active ? "has-text-primary" : "")}>
          { props.title }
        </h1>

        <h2 class="subtitle is-6 pt-1">
          { props.subtitle }
        </h2>
      </div>
    </>
  )
}


class VerticalTabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0
    };
  }

  handleClick = (tab) => {
    this.setState(prev => ({
      activeTab: tab
    }))
  }

  render() {
    return (
      <>
        <div class="is-hidden-desktop">
          <div class="box px-6">
            <div class="tabs is-medium is-fullwidth">
              <ul>
                {
                  this.props.children.map((child, i) => {
                    return (
                      <TabHeader
                        title={child.props.children.filter((elem) => elem.type === "h1")[0]["props"]["children"]}
                        subtitle={child.props.children.filter((elem) => elem.type === "h2")[0]["props"]["children"]}
                        active={this.state.activeTab === i}
                        onClick={this.handleClick.bind(this, i)}
                      />
                    )
                  })
                }
              </ul>
            </div>

            <div class="pt-4">
              {
                this.props.children.map((child, i) => {
                  return (
                    <div class={"pb-2" + (this.state.activeTab === i ? "" : " is-hidden")}>
                      {child.props.children.filter((elem) => elem.type === "section")[0]}
                    </div>
                  )
                })
              }
            </div>

            <div style={{height: "4rem"}}>
              {
                this.props.children.map((child, i) => {
                  return (
                    <div class={"case-link-mobile is-pulled-right" + (this.state.activeTab === i ? "" : " is-hidden")}>
                      {child.props.children.filter((elem) => elem.type === "nav")[0]}
                    </div>
                  )
                })
              }
            </div>
          </div>

        </div>

        <div class="is-hidden-touch">
          <div class="tile is-ancestor">
            <div class="tile box is-parent is-horizontal">
              <div class="tile is-child is-4">
                <nav class="vtab datanav">
                  {
                    this.props.children.map((child, i) => {
                      return (
                        <TabHeader
                          title={child.props.children.filter((elem) => elem.type === "h1")[0]["props"]["children"]}
                          subtitle={child.props.children.filter((elem) => elem.type === "h2")[0]["props"]["children"]}
                          active={this.state.activeTab === i}
                          onClick={this.handleClick.bind(this, i)}
                        />
                      )
                    })
                  }
                </nav>
              </div>

              <div class="tile is-child px-3 py-3 is-relative">
                <div class="key-takeaway">
                  {
                    this.props.children.map((child, i) => {
                      return (
                        <div class={this.state.activeTab === i ? "" : "is-hidden"}>
                          {child.props.children.filter((elem) => elem.type === "section")[0]}
                        </div>
                      )
                    })
                  }
                </div>
                {
                  this.props.children.map((child, i) => {
                    return (
                      <div class={"case-link is-pulled-right" + (this.state.activeTab === i ? "" : " is-hidden")}>
                        {child.props.children.filter((elem) => elem.type === "nav")[0]}
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

VerticalTabs.defaultProps = {
  backdrop: true
}

export default VerticalTabs;
