import React, { Component} from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHardHat, faCogs, faLightbulb, faUniversity } from '@fortawesome/free-solid-svg-icons'

import yellow from 'assets/navbar-logo-yellow.svg';
import blue from 'assets/navbar-logo-blue.svg';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      burgerToggle: false
    };
  }

  toggleMenu = () => {
    this.setState(prev => ({
      burgerToggle: !prev.burgerToggle
    }))
  }

  render() {
    return (
      <nav class={"navbar" + (this.props.transparent ? " is-hero" : " is-white")} role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
          <Link class="navbar-item no-hover" aria-label="Home" to="/">
            <img src={this.props.transparent ? yellow : blue } alt="logo" width="212" height="100"></img>
          </Link>

          <div role="button" class={"navbar-burger burger" + (this.state.burgerToggle ? " is-active" : "")} aria-label="menu" aria-expanded="false" data-target="navbarItems" onClick={this.toggleMenu}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>


        <div id="navbarItems" class={"navbar-menu" + (this.state.burgerToggle ? " is-active" : "")}>
          <div class="navbar-end">

            <div class="navbar-item has-dropdown is-hoverable">
              <div class="navbar-link is-arrowless is-size-5 has-text-weight-semibold">
                Product
              </div>

              <div class="navbar-dropdown is-boxed">
                <Link class="navbar-item is-dropdown" to="/consulting">
                  <div>
                    <h1 class="icon-text is-size-5 has-text-weight-semibold">
                      <span class="icon is-medium has-text-primary-dark">
                        <FontAwesomeIcon icon={faHardHat} size="xs"/>
                      </span>
                      <span>
                        Professional Services
                      </span>
                    </h1>

                    <p>
                      Get help from our team of friendly experts
                    </p>
                  </div>
                </Link>

                <hr class="navbar-divider" />

                <Link class="navbar-item is-dropdown" to="/product">
                  <div>
                    <h1 class="icon-text is-size-5 has-text-weight-semibold">
                      <span class="icon is-medium has-text-primary-dark">
                        <FontAwesomeIcon icon={faCogs} size="xs"/>
                      </span>
                      <span>
                        Data Sharing Solutions
                      </span>
                    </h1>
                    <p>
                      Use our plug and play data ecosystem product <br />
                      to accelerate your data sharing needs
                    </p>
                  </div>
                </Link>

              </div>
            </div>

            <div class="navbar-item has-dropdown is-hoverable">
              <div class="navbar-link is-arrowless is-size-5 has-text-weight-semibold">
                Resources
              </div>

              <div class="navbar-dropdown is-boxed">
                <Link class="navbar-item is-dropdown" to="/concepts">
                  <div>
                    <h1 class="icon-text is-size-5 has-text-weight-semibold">
                      <span class="icon is-medium has-text-primary-dark">
                        <FontAwesomeIcon icon={faLightbulb} size="xs"/>
                      </span>
                      <span>
                        Concepts
                      </span>
                    </h1>

                    <p>
                      Learn key data sharing concepts
                    </p>
                  </div>
                </Link>

                <hr class="navbar-divider" />

                <Link class="navbar-item is-dropdown" to="/cases">
                  <div>
                    <h1 class="icon-text is-size-5 has-text-weight-semibold">
                      <span class="icon is-medium has-text-primary-dark">
                        <FontAwesomeIcon icon={faUniversity} size="xs"/>
                      </span>
                      <span>
                        Use Cases
                      </span>
                    </h1>

                    <p>
                      Explore relevant use cases
                    </p>
                  </div>
                </Link>


              </div>
            </div>

            <Link class="navbar-item is-size-5 has-text-weight-semibold" to="/about">
              About
            </Link>

            <Link class="navbar-item is-size-5 has-text-weight-semibold" to="/contact">
              Contact
            </Link>

            <hr class="navbar-divider" />

            <div class="navbar-item">
              <div class="buttons">
                <a class={"button is-medium is-success" + (this.props.transparent ? " is-outlined" : "")} href="https://datagarden.app">
                  <strong>Explore</strong>
                </a>
                <Link class={"button is-hidden is-medium is-light" + (this.props.transparent ? " is-outlined" : "")} to="/login">
                  Log in
                </Link>
              </div>
            </div>

          </div>

        </div>
      </nav>
    )
  }
}

export default Navbar;
