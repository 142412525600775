import React, { Component } from 'react';

import './TitleBox.sass';

class TitleBox extends Component {
  backgroundStyle = {
    backgroundImage: `url(${this.props.image})`
  }

  render() {
    return (
      <div>
        <section class="hero is-halfheight" style={this.backgroundStyle} id="has-background" />

        <div class="container">
          <div class="columns is-mobile is-gapless is-centered">
            <div class="column is-7-desktop is-9-tablet is-9-mobile title-box">
              <div class="title-box-dropshadow " />
              <p class="title is-2 has-text-centered py-6">
                { this.props.title }
              </p>
              <p class="subtitle is-3 has-text-centered">
                { this.props.subtitle }
              </p>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TitleBox;

