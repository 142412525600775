import React, { Component } from 'react';

import Section from 'components/Section';
import TitleBox from 'components/TitleBox';
import PrevNextPost from 'components/PrevNextPost';

import background from 'assets/health-research.jpg';

class HealthLayout extends Component {
  render() {
    return (
      <div>
        <TitleBox
          image={background}
          title="Health: Accelerated Research"
          subtitle="Co-own and develop an environment to share anonymised data into a common pool"
        />

        <Section>
          <div class="container is-max-desktop" id="no-px-mobile">
            <div class="columns is-centered is-mobile">
              <div class="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop">
                <div class="content has-text-justified px-1">
                  <h2> Problem </h2>

                  <p> Often researchers at each institution face a shortage of examples within their own data sets when looking for patterns, particularly for rarer conditions or specific types of cancer, or trials on drugs and therapies don’t include enough participants from minority groups who may have different outcomes.  </p>

                  <p> Medical institutes need comprehensive data to develop new therapies, and health plans and medical providers need data to make care more efficient. </p>

                  <p> Although stores of digital data —  prescriptions, medical procedures, insurance claims — have grown rapidly, the coordination, harmonisation and ethics around sharing such data are still complex and evolving. </p>

                  <h2> Solution </h2>
                  <p> Hospitals, research organisations and a trusted data intermediary can co-own and develop an environment to share anonymised data into a common pool.</p>

                  <p>Each entity manages which data is shared into the common environment, ascribe to relevant taxonomies, which are centrally linked and transformed.</p>

                  <p>The database is representative and offers the opportunity for the whole healthcare industry to progress on a comprehensive database.</p>

                  <h2>Outcome</h2>
                  <ul>
                    <li>Cutting through “infodemic” & enabling better response to emergency outbreaks</li>
                    <li>Data-driven learnings for increased efficacy of interventions</li>
                    <li>Enable more accurate predictive models and increase efficiencies in drug discovery</li>
                    <li>Improve care system overall</li>
                    <li>Develop better therapies and drugs</li>
                    <li>Promote more equitable treatment of underrepresented groups</li>
                  </ul>
                  <h2>Real Life Examples</h2>
                  <ul>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://truveta.com/">Truveta</a> is a company founded by a consortium of healthcare institutes in America.</li>
                  <li>The <a target="_blank" rel="noopener noreferrer" href="https://www.pistoiaalliance.org/projects/current-projects/ontologies-mapping/">Pistoia Alliance</a> makes ontologies about medical research available</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.melloddy.eu/objectives">Melloddy</a> is using machine learning to evaluate drug discovery-relevant predictive models on a dataset compiled by a consortium of partners</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://data4covid19.org/">Data4Covid</a> tracks data sharing initiatives related to the Covid pandemic</li>
                  </ul>
                </div>

                <br/><br/>
                <PrevNextPost
                  nextHref="/cases/cities-utilities-consumption"
                  nextTitle="Cities: Utilities Consumption"
                />

              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default HealthLayout;

