import React, { Component} from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

import './Card.css';

class Card extends Component {
  render() {
    return (
      <Link class="mx-3 mb-4" to={ this.props.href }>
        <div class="card">
          { this.props.image ?
            (
              <div class="card-image">
                <figure class="image is-4by3">
                  <img src={ this.props.image } alt="" />
                </figure>
              </div>
            ) : null
          }
          <div class="card-content">
            <div class="media-content" style={this.props.customCss || {minHeight: "7rem"}}>
              <h1 class="title is-4">
                { this.props.icon ?
                  (
                    <span class="mr-1"> <FontAwesomeIcon icon={this.props.icon} size="lg"/> </span>
                  ) : null
                }
                { this.props.title }
              </h1>

              { this.props.subtitle ?
                (
                  <h2 class="subtitle pt-3"> { this.props.subtitle } </h2>
                ) : null
              }
            </div>

            <div class="content" style={{position: "absolute", bottom: "10px"}}>
              <h2 class="subtitle is-5"> { this.props.cta || "Read More" } <span> <FontAwesomeIcon icon={faAngleRight} size="xs"/> </span> </h2>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default Card;
