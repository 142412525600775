import React, { Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoins, faBed, faClock } from '@fortawesome/free-solid-svg-icons'

import dashboard from 'assets/dashboard.svg';

class Introduction extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  render() {
    return (
      <section class="section">
        <div class="container">
          <div class="anchor" id="features"></div>
          <div class="columns is-centered is-vcentered px-6" id="no-px-mobile">

            <div class="column is-three-fifths pl-6 ml-4" id="no-px-mobile">
              <h1 class="title has-text-left">
                <span class="title has-text-primary-dark"> Data Pools </span> made easy
              </h1>
              <br />

              <h2 class="subtitle has-text-left">
                <div class="block">
                  PixelCities provides a <span class="has-text-weight-bold"> plug and play data pool product </span> for multi stakeholder data collaboratives. <br />
                </div>
                <div class="block">
                  Contribute data securely to a common data space and <span class="has-text-weight-bold"> enable insights on integrated datasets</span>. <br />
                </div>
              </h2>
              <br />

              <div className="content subtitle has-text-left">
                <ul className="fa-ul" style={{listStyleType: "none"}} >
                  <li><span class="fa-li"><FontAwesomeIcon icon={faCoins} fixedWidth size="xs"/></span>
                    Save costs by adopting a ready made solution
                  </li>

                  <li><span class="fa-li"><FontAwesomeIcon icon={faClock} fixedWidth size="xs"/></span>
                    Save time because you do not need to learn to code in order to create secure sharing solutions
                  </li>

                  <li><span class="fa-li"><FontAwesomeIcon icon={faBed} fixedWidth size="xs"/></span>
                    Get a good night's rest knowing your data is secure
                  </li>
                </ul>
              </div>
              <br />

            </div>
            <div class="column ml-6 is-hidden-mobile ">
              <figure class="image is-256x256">
                <img src={dashboard} alt="" />
              </figure>
            </div>

          </div>
        </div>

      </section>

    )
  }
}

export default Introduction;
