import React, { Component } from 'react';

import Section from 'components/Section';
import TitleBox from 'components/TitleBox';

import background from 'assets/datapool-privacy.jpg';

class DataPoolsLayout extends Component {
  render() {
    return (
      <div>
        <TitleBox
          image={background}
          title="What are Data Pools?"
          subtitle="Creating more value through the aggregation of different data sources"
        />

        <Section>
          <div class="container is-max-desktop" id="no-px-mobile">
            <div class="columns is-centered is-mobile">
              <div class="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop">
                <div class="content has-text-justified px-1">
                  <p><em>Decision makers and data owners are starting to recognise the value of generating insights from shared data, as opposed to standalone - and data collaboratives, or more commonly, data pools, are an emerging solution to this problem.</em></p>
                  <p><em>Despite the momentum for data sharing, there are still many technological and organisational challenges that impact the success of data sharing initiatives between inter-organisational actors.</em></p>
                  <p><em>In this article, we review the different ways to share data and some common problems with sharing data.</em></p>
                </div>

                <div class="content has-text-justified px-1">
                  <h3>Data sharing in practice</h3>
                  <p>There are currently two major trends in data sharing collaborations: business-to-business (B2B) and business-to-government (B2G) partnerships.</p>
                  <p>B2B data sharing partnerships are increasingly common with the rise of big data and AI, where more data is frequently directly associated with more value. But being a data driven enterprise is no longer reserved for tech companies, and companies from every industry need data to drive innovation and strategic insights. Yet, while most companies recognize the need for data driven processes, very few have been able to grow a sufficient data culture with mature data analytics solutions. And those that have started to embrace data, are holding onto a critical asset that often remains locked in departmental and organisational silos. The existence of organisational and data silos are among the main barriers for transforming organisations into data driven enterprises<sup><a href="#footnote-1">[1]</a></sup>.</p>
                  <p>B2G data sharing is instead finding its way into policy making due to the recognized value of data and thus the value of privately held data for public-interest purposes. But while there is an understanding of the importance of data and data sharing, the technical difficulties are still widespread due to a severe lack of digital skills and resources necessary to handle the data itself and to carry the collaborations through. Contractual agreements are still the main tool for B2G data sharing, yet contracts are often phrased in a highly restrictive way which may render the use of the data unworkable<sup><a href="#footnote-2">[2]</a></sup>.</p>

                  <h3>Data sharing problems</h3>
                  <p>Inter-organisational collaborations are notoriously difficult because of problems ranging from technical and organisational compatibility, to financial capacity and expected return on investment. No organisation is alike, and aligning agendas, human and technological resources, and communication processes make for complex cost estimates.</p>
                  <p>The key requirement to inter-organisational data sharing is the financial capacity to invest in a data sharing project where ultimately the benefits outweigh the (upfront) costs<sup><a href="#footnote-3">[3]</a></sup>. These costs are mostly driven by technical (privacy, security, infrastructure and data interoperability)<sup><a href="#footnote-4">[4]</a></sup> and organisational (communication processes, project management, political distance)<sup><a href="#footnote-5">[5]</a></sup> incompatibilities that need to be resolved. Based on all these determinants, there is finally also the notion of trust between the stakeholders of the collaborative.</p>

                  <h3>Data sharing distinctions</h3>
                  <p>Depending on the type of data collaborative, trust may play a different role in the governance of the data sharing initiative. For example, between private-private actors there is usually the motivation of profit maximization, while wanting to retain intellectual property and competitive advantage. In this use case, inter party trust is likely to be minimal and will be guaranteed through legal and technical constructs instead. On the other hand, within public-public or public-private partnerships there is generally an incentive to maximize the common good. In these collaborations, trust may instead extend to the data subject, with safeguards to protect a citizen’s privacy<sup><a href="#footnote-6">[6]</a></sup>.</p>
                  <p>The nomenclature of these different types of data governance models can roughly be summarized as follows, although they are often used interchangeably:<sup><a href="#footnote-7">[7]</a></sup></p>
                  <ol>
                    <li><strong>Data Pools</strong> or Data Sharing Pools: The aim of producing data-driven innovation and economic benefits for all parties involved.</li>
                    <li><strong>Data Cooperatives</strong>: Pooling data for the common good, often to promote societal change and address societal issues.</li>
                    <li><strong>Data Trusts</strong> or Public Data Trusts: Multiple stakeholders including at least one public body to enable insights for policy making.</li>
                  </ol>

                  <p>Within the context of the digitization race, the current trend of data hoarding will soon come to an end and make way for a data integration revolution. Small amounts of data become disproportionately more valuable through aggregation and integration with other data. This holds true for the integration of data among multi organisational stakeholders using a data collaborative.</p>

                  <blockquote class="has-text-left mx-6">PixelCities builds data sharing solutions and provides a plug and play platform for the most important requirements to bring a data collaborative to fruition.</blockquote>

                  <p>PixelCities allows you to bring together your most important stakeholders, and collaboratively build towards your data solution in a secure and private manner. It greatly reduces your upfront investment by taking away technological challenges and infrastructure requirements, keeping it all together with a centralized project management system that integrates with all your processes.</p>

                </div>

                <br />
                <div class="content no-wrap no-block is-small px-1">
                  <h3> FOOTNOTES </h3>
                  <p><sup id="footnote-1">[1] </sup><a target="_blank" rel="noopener noreferrer" href="https://hbr.org/resources/pdfs/comm/snowflake/AnInflectionPoint.pdf">https://hbr.org/resources/pdfs/comm/snowflake/AnInflectionPoint.pdf</a></p>
                  <p><sup id="footnote-2">[2] </sup><a target="_blank" rel="noopener noreferrer" href="https://ec.europa.eu/newsroom/dae/document.cfm?doc_id=64954">https://ec.europa.eu/newsroom/dae/document.cfm?doc_id=64954</a></p>
                  <p><sup id="footnote-3">[3] </sup><a target="_blank" rel="noopener noreferrer" href="https://www.researchgate.net/publication/222158992_A_strategic_analysis_of_inter_organizational_information_sharing">https://www.researchgate.net/publication/222158992_A_strategic_analysis_of_inter_organizational_information_sharing</a></p>
                  <p><sup id="footnote-4">[4] </sup><a target="_blank" rel="noopener noreferrer" href="https://aisel.aisnet.org/cgi/viewcontent.cgi?article=1187&context=ecis2015_cr">https://aisel.aisnet.org/cgi/viewcontent.cgi?article=1187&context=ecis2015_cr</a></p>
                  <p><sup id="footnote-5">[5] </sup><a target="_blank" rel="noopener noreferrer" href="https://www.sciencedirect.com/science/article/am/pii/S0740624X16300090">https://www.sciencedirect.com/science/article/am/pii/S0740624X16300090</a></p>
                  <p><sup id="footnote-6">[6] </sup><a target="_blank" rel="noopener noreferrer" href="https://www.researchgate.net/publication/220175407_Barriers_to_Interorganizational_Information_Sharing_in_E-government_A_Stakeholder_Analysis">https://www.researchgate.net/publication/220175407_Barriers_to_Interorganizational_Information_Sharing_in_E-government_A_Stakeholder_Analysis</a></p>
                  <p><sup id="footnote-7">[7] </sup><a target="_blank" rel="noopener noreferrer" href="https://journals.sagepub.com/doi/full/10.1177/2053951720948087">https://journals.sagepub.com/doi/full/10.1177/2053951720948087</a></p>
                </div>

              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default DataPoolsLayout;

