import React, { Component } from 'react';

import Section from 'components/Section';
import TitleBox from 'components/TitleBox';
import PrevNextPost from 'components/PrevNextPost';

import background from 'assets/sustainability-data-aggregation.jpg';

class SustainabilityDataLayout extends Component {
  render() {
    return (
      <div>
        <TitleBox
          image={background}
          title="Sustainability Data Aggregation"
          subtitle="Organisations that gather ESG data often use cumbersome and error prone tools"
        />

        <Section>
          <div class="container is-max-desktop" id="no-px-mobile">
            <div class="columns is-centered is-mobile">
              <div class="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop">
                <div class="content has-text-justified px-1">

                  <h2>Problem </h2>

                  <p>Agencies and organizations that gather data and assess risks about the Environment, Society and Governance do so through surveying other organisations (whether companies or local authorities, or even other smaller similar agencies), and then aggregating and preparing this data for use by a wider audience.</p>
                  <p>Capturing this data is a manual process, and co-ordinating with multiple data owners and sources within an entity can be time consuming and frustrating.</p>
                  <p>Furthermore, entities may have to provide data into various ESG reporting systems and pools.</p>

                  <h2>Solution</h2>
                  <p>Data capture that is currently done through forms and templated sheets, may be easily compiled and managed in a private organisation data space that extracts data from an entities existing information system and aids its transformation into the common taxonomy.</p>
                  <p>Updates to the centralized dashboard can happen on a more dynamic basis, with data completeness and sources visible to the viewers. </p>
                  <p>Furthermore, updating figures upstream, can lead to controlled yet automatic recalculations, removing barriers to correcting data for fear of extra work.</p>

                  <h2>Outcome</h2>
                    <ul>
                    <li>Increase timeously of ESG data for the general viewers and researchers</li>
                    <li>Increase transparency of ESG data sources for general viewers and researchers</li>
                    <li>Facilitate ESG data agencies in co-ordinating data capture with multiple entities</li>
                    <li>Remove barriers for entities to share ESG data to common dashboard</li>
                  </ul>

                  <h2>Examples</h2>
                  <ul>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.eea.europa.eu/about-us/countries-and-eionet">European Environment Agency</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://gresb.com/">Gresb</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.cdp.net/en/guidance">Carbon Disclosure Project</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.trucost.com/">Trucost</a></li>
                  </ul>

                </div>

                <br/><br/>
                <PrevNextPost
                  prevHref="/cases/cities-utilities-consumption"
                  prevTitle="Cities: Utilities Consumption"
                  nextHref="/cases/cities-maintenance"
                  nextTitle="Cities: Coordinated Maintenance"
                />

              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default SustainabilityDataLayout;

