import React, { Component} from 'react';
import axios from 'axios';

import Section from 'components/Section';

const LOGIN_ENDPOINT = "https://pzjoynsww4.execute-api.eu-central-1.amazonaws.com/dev/login"

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      error: false
    };
  }

  handleUsername = (e) => {
    this.setState({username: e.target.value});
  };

  handlePassword = (e) => {
    this.setState({password: e.target.value});
  };

  handleMessageButton = (e) => {
    this.setState({error: false});
  };

  handleSubmit = (e) => {
    e.preventDefault();

    axios.post(LOGIN_ENDPOINT, {
      "username": this.state.username,
      "password": this.state.password
    }, {
      headers: { "Content-Type": "application/json" }
    }).then((response) => {
    }).catch((e) => {
      this.setState({error: true});
      console.log(e);
    });

    this.setState({
      username: "",
      password: ""
    });
  }

  render() {
    return (
      <Section>
        <div class="container">

          { this.state.error ?
            <article class="message is-danger">
              <div class="message-header">
                <p>Invalid username or password</p>
                <button class="delete" aria-label="delete" onClick={this.handleMessageButton} />
              </div>
            </article>
          : null }

          <h1 class="title is-size-2 has-text-centered">
            PixelCities
          </h1>

          <p class="subtitle pt-3 is-size-5 has-text-centered is-italic">
            PixelCities is currently being developed. Contact us to get involved.
          </p>

          <br /><br />

          <div class="columns is-centered">
            <div class="column is-half">
              <div class="box">
                <div class="field">
                  <p class="control has-icons-left has-icons-right">
                    <input class="input" type="email" placeholder="Username" value={this.state.username} onChange={this.handleUsername} />
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                    <span class="icon is-small is-right">
                      <i class="fas fa-check"></i>
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control has-icons-left">
                    <input class="input" type="password" placeholder="Password" value={this.state.password} onChange={this.handlePassword} />
                    <span class="icon is-small is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                  </p>
                </div>
                <div class="field is-grouped is-grouped-right">
                  <p class="control">
                    <button class="button is-primary" onClick={this.handleSubmit}>
                      Login
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Section>

    )
  }
}

export default Login;
