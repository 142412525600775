import React, { Component } from 'react';
import { Route } from "react-router-dom";

import Section from 'components/Section';
import Navbar from 'components/Navbar';

import ProductHero from './ProductHero';
import ProductFeatures from './ProductFeatures';
import ProductPrivacy from './ProductPrivacy';

class ProductRoute extends Component {
  parentPath = this.props.match.path;

  render() {
    return (
      <div>
        <Navbar transparent={false} />

        <Route path={this.parentPath} component={Product} />
      </div>
    )
  }
}


class Product extends Component {

  render() {
    return (
      <Section>
        <ProductHero />
        <ProductFeatures />
        <ProductPrivacy />

      </Section>
    );
  }
}

export default ProductRoute;
