import React, { FC } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'


const PrevNextPost: FC = (props) => {
  return (
    <div className="columns">
      <div className="column is-half">
        { props.prevHref && props.prevTitle && (
          <Link to={props.prevHref} className="is-pulled-left" style={{maxWidth: "75%"}}>
            <div className="is-relative">
            <p className="subtitle is-4" style={{color: "#bdbdbd", paddingLeft: "3rem"}}>
                Previous Case
              </p>
              <span style={{position: "absolute", left: 0, top: "50%", transform: `translateY(${-35/2}px)`}}>
                <FontAwesomeIcon icon={faAngleLeft} size="2x"/>
              </span>
              <p className="subtitle is-5" style={{paddingLeft: "3rem"}}>
                { props.prevTitle }
              </p>
            </div>
          </Link>
        )}
      </div>

      <div className="column is-half">
        { props.nextHref && props.nextTitle && (
          <Link to={props.nextHref} className="is-pulled-right" style={{maxWidth: "75%"}}>
            <div className="is-relative">
            <p className="subtitle is-4" style={{color: "#bdbdbd", paddingRight: "3rem"}}>
                Next Case
              </p>
              <span style={{position: "absolute", right: 0, top: "50%", transform: `translateY(${-35/2}px)`}}>
                <FontAwesomeIcon icon={faAngleRight} size="2x"/>
              </span>
              <p className="subtitle is-5" style={{paddingRight: "3rem"}}>
                { props.nextTitle }
              </p>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}


export default PrevNextPost;
