import React, { Component } from 'react';

import Card from 'components/Card';

import datasynth from 'assets/datasynth-building.jpg';

class DataSynthCard extends Component {
  render() {
    return (
      <Card
        image={datasynth}
        title="What is Synthetic Data?"
        href="/concepts/what-is-synthetic-data"
      />
    )
  }
}

export default DataSynthCard;
