import React, { FC, Component } from 'react';
import { Switch, Route } from "react-router-dom";

import Section from 'components/Section';
import Navbar from 'components/Navbar';

import { DataPoolsLayout, DataPoolsCard } from './DataPools';
import { DataSynthLayout, DataSynthCard } from './DataSynth';

class ConceptsRoute extends Component {
  parentPath = this.props.match.path;

  render() {
    return (
      <div>
        <Navbar transparent={false} />

        <Switch>
          <Route path={this.parentPath + "/what-are-data-pools"} component={DataPoolsLayout} />
          <Route path={this.parentPath + "/what-is-synthetic-data"} component={DataSynthLayout} />
          <Route path={this.parentPath} component={ConceptsLayout} />
        </Switch>
      </div>
    )
  }
}

const ConceptsLayout: FC = () => {
  return (
    <Section>
      <div class="container">
        <h1 class="title is-2 pb-5">
          Learning resources
        </h1>

        <h2 class="subtitle is-4 pb-5">
          There's a lot to learn about data ecosystems, privacy, and security, but don't worry: We're here to help you! These pages are an easy and friendly way of learning new things.
        </h2>

        <div class="columns is-centered is-vcentered is-multiline is-variable is-1-mobile is-1-tablet is-3-desktop px-6" id="no-px-mobile">

          <div class="column is-4">
            <DataPoolsCard />
          </div>

        </div>
      </div>
    </Section>
  );
}

export default ConceptsRoute;
