import React, { Component } from 'react';

import Section from 'components/Section';
import TitleBox from 'components/TitleBox';
import PrevNextPost from 'components/PrevNextPost';

import background from 'assets/consumer-protection.jpg';

class ConsumerProtectionLayout extends Component {
  render() {
    return (
      <div>
        <TitleBox
          image={background}
          title="Consumer Protection"
          subtitle="Data about people, their preferences and their behavior are becoming an increasingly important resource for companies, authorities and research institutions"
        />

        <Section>
          <div class="container is-max-desktop" id="no-px-mobile">
            <div class="columns is-centered is-mobile">
              <div class="column is-full-mobile is-four-fifths-tablet is-three-quarters-desktop">
                <div class="content has-text-justified px-1">

                  <h2>Problem</h2>

                  <p>Data about people, their preferences and their behavior are becoming an increasingly important resource for companies, authorities and research institutions. Consumers have to decide which of the data about them will be passed on and for what purpose. </p>
                  <p>On the one hand, they want to ensure that they are not used to reveal confidential details of their private life or pursue other undesirable purposes. On the other hand, they like to benefit from personalized products and innovations that are created using the same data.</p>
                  <p>Data collection is so complex that consumers are overwhelmed and many of them resignedly accept data protection declarations without knowing the consequences.  At the same time, a few large companies collect and store huge amounts of data that enable them to leverage insights across markets and consumers. </p>

                  <h2>Solution</h2>
                  <p>Intermediaries can aggregate the interests and data of consumers and represent them to organizations that use data in a way that is compliant with the preferences of consumers. </p>
                  <p>These intermediaries have deep technical and legal expertise, and take the pressure off consumers.</p>
                  <p>Consumers can centrally engage with one data trust to manage the data that is delivered to all other companies.</p>

                  <h2>Outcome</h2>
                  <ul>
                    <li>Citizens, indirectly, are able to negotiate about the terms of data usage</li>
                    <li>Personal information does not enter into the domain of the companies who utilise it for innovations</li>
                    <li>Product innovations are still possible with aggregated datasets</li>
                  </ul>

                </div>
                <br />
                <div class="content no-wrap no-block is-small px-1">
                  <h3> REFERENCES </h3>
                  <p><a target="_blank" rel="noopener noreferrer" href="https://www.stiftung-nv.de/de/publikation/designing-data-trusts-why-we-need-test-consumer-data-trusts-now">Designing Data Trusts. Why We Need to Test Consumer Data Trusts Now. (2020, February 28). Stiftung Neue Verantwortung (SNV)</a></p>
                  <p><a target="_blank" rel="noopener noreferrer" href="https://hello.elementai.com/data-trusts.html">Element AI, NESTA, Ministry of Innovation, Science and Economic Development, Canada, & IVADO. (2019). Data Trusts A New Tool for Data Governance</a></p>
                </div>

                <br/><br/>
                <PrevNextPost
                  prevHref="/cases/cities-maintenance"
                  prevTitle="Cities: Coordinated Maintenance"
                  nextHref="/cases/agriculture-accelerated-research"
                  nextTitle="Agriculture: Accelerated Research"
                />

              </div>
            </div>
          </div>
        </Section>
      </div>
    );
  }
}

export default ConsumerProtectionLayout;

