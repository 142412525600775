import React, { FC, useRef, useEffect, useState } from 'react';

import './LaptopFrame.sass';

const LaptopFrame: FC = (props) => {
  const backgroundStyle = {
    backgroundImage: `url(${props.image})`,
    backgroundSize: "cover"
  }


  return (
    <div class="laptop" style={backgroundStyle} >
      <div class="base" />
    </div>
  )
}

LaptopFrame.defaultProps = {
  backdrop: true
}

export default LaptopFrame;
