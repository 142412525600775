import React, { useRef, useState, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faFlagCheckered } from '@fortawesome/free-solid-svg-icons'

import birdIcon from 'assets/bird-simple-blue.svg';

import './Breakdown.sass';

const Breakdown: FC = () => {

  const startRef = useRef(null)
  const endRef = useRef(null)
  const relRef = useRef(null)

  const htRef1 = useRef(null)
  const htRef2 = useRef(null)
  const htRef3 = useRef(null)
  const htRef4 = useRef(null)
  const hbRef1 = useRef(null)
  const hbRef2 = useRef(null)
  const hbRef3 = useRef(null)
  const hbRef4 = useRef(null)

  const [minHeightTop, setMinHeightTop] = useState(135)
  const [minHeightBottom, setMinHeightBottom] = useState(160)

  const [startPosition, setStartPosition] = useState({x: 0, y: 0})
  const [endPosition, setEndPosition] = useState({x: 0, y: 0})

  useLayoutEffect(() => {
    if (startRef && endRef && relRef) {
      const rect1 = startRef.current.getBoundingClientRect()
      const rect2 = endRef.current.getBoundingClientRect()
      const rect3 = relRef.current.getBoundingClientRect()

      const startLeft = rect1.right + window.pageXOffset
      const startTop = rect1.top + ( rect1.height / 2 ) + window.pageYOffset

      const endLeft = rect2.left + window.pageXOffset
      const endTop = rect2.top + ( rect2.height / 2 ) + window.pageYOffset

      const relLeft = rect3.left + window.pageXOffset
      const relTop = rect3.top + window.pageYOffset

      setStartPosition({x: startLeft - relLeft, y: startTop - relTop})
      setEndPosition({x: endLeft - relLeft, y: endTop - relTop})

    }
  }, [startRef, endRef, relRef])

  useLayoutEffect(() => {
    if (htRef1 && htRef2 && htRef3 && htRef4) {
      setMinHeightTop(Math.max(
        htRef1.current.offsetHeight,
        htRef2.current.offsetHeight,
        htRef3.current.offsetHeight,
        htRef4.current.offsetHeight
      ))
    }
  }, [htRef1, htRef2, htRef3, htRef4])

  useLayoutEffect(() => {
    if (hbRef1 && hbRef2 && hbRef3 && hbRef4) {
      setMinHeightBottom(Math.max(
        hbRef1.current.offsetHeight,
        hbRef2.current.offsetHeight,
        hbRef3.current.offsetHeight,
        hbRef4.current.offsetHeight
      ))
    }
  }, [hbRef1, hbRef2, hbRef3, hbRef4])

  const pixieBox = (ref, text) => {
    return (
      <div class="column is-one-quarter pt-0">
        <div ref={ref} class="div bottom-box is-10 is-offset-1 is-relative" style={{minHeight: `${minHeightBottom}px`}}>
          <div className="divider-box">
            <div className="divider-pxc">
              <div className="divider-content">
                <figure class="image is-32x32 has-image-centered"> <img src={birdIcon} alt="" /> </figure>
                <p className="is-size-7 has-text-centered">PixelCities</p>
              </div>
            </div>
          </div>

          <div className="tagtext">
            { text }
          </div>

          {/*
          <div style={{position: "absolute", bottom: "10px", right: "10px"}}>
            <figure class="image is-32x32 has-image-centered"> <img src={birdIcon} alt="" /> </figure>
            <p className="is-size-7">PixelCities</p>
          </div>
           */}
        </div>
      </div>
    )
  }

  return (
    <section class="section">
      <div class="container is-fullhd is-hidden-desktop">
        <h1 id="use-cases" class="title is-size-2 has-text-centered">
          How does it work?
        </h1>
        <br />

        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <table class="table ">
              <thead>
                <tr>
                  <th><span class="has-text-centered">
                    <figure class="image is-48x48 has-image-centered"> <FontAwesomeIcon icon={faUser} size="2x"/> </figure>
                    <p>You</p>
                  </span></th>
                  <th><span class="has-text-centered">
                    <figure class="image is-48x48 has-image-centered"> <img src={birdIcon} alt="" /> </figure>
                    <p>PixelCities</p>
                  </span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="has-text-left"> A group of organisations come together over a common problem </td>
                  <td class="has-text-left"> We deploy a support team to your project </td>
                </tr>
                <tr>
                  <td class="has-text-left"> The project lead commissions the data pool </td>
                  <td class="has-text-left"> Your data pool environment is up and ready to be used </td>
                </tr>
                <tr>
                  <td class="has-text-left"> Plug your data into the common dashboard </td>
                  <td class="has-text-left"> We offer support with data integration and analysis </td>
                </tr>
                <tr>
                  <td class="has-text-left"> Tell your story to wider audiences </td>
                  <td class="has-text-left"> We provide a communicable brochure about your data pool </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>


      <div class="container is-fullhd is-hidden-touch">
        <h1 id="use-cases" class="title is-size-2 has-text-centered">
          How does it work?
        </h1>
        <br />

        <div ref={relRef} class="columns is-relative">
          <div className="tether" style={{left: `${startPosition.x}px`, top: `${startPosition.y}px`, width: `${endPosition.x - startPosition.x}px`}} / >

          <div class="column is-1 has-text-centered">
            <div ref={startRef} class="div is-vcentered">
              <figure class="image is-48x48 has-image-centered"> <FontAwesomeIcon icon={faUser} size="2x"/> </figure>
              <p>You</p>
            </div>
          </div>

          <div class="column is-10 pb-0">
            <div class="columns">
              <div class="column is-one-quarter">
                <div ref={htRef1} class="div top-box is-10 is-offset-1" style={{minHeight: `${minHeightTop}px`}}>
                  A group of organisations come together over a common problem

                </div>
              </div>

              <div class="column is-one-quarter">
                <div ref={htRef2} class="div top-box is-10 is-offset-1" style={{minHeight: `${minHeightTop}px`}}>
                  The project lead commissions the data pool
                </div>
              </div>

              <div class="column is-one-quarter">
                <div ref={htRef3} class="div top-box is-10 is-offset-1" style={{minHeight: `${minHeightTop}px`}}>
                  Plug your data into the common dashboard
                </div>
              </div>

              <div class="column is-one-quarter">
                <div ref={htRef4} class="div top-box is-10 is-offset-1" style={{minHeight: `${minHeightTop}px`}}>
                  Tell your story to wider audiences
                </div>
              </div>
            </div>
          </div>

          <div class="column is-1 has-text-centered">
            <div ref={endRef} class="div is-vcentered">
              <figure class="image is-48x48 has-image-centered"> <FontAwesomeIcon icon={faFlagCheckered} size="2x"/> </figure>
              <p></p>
            </div>
          </div>

        </div>

        <div class="columns pt-0">
          <div class="column is-1 has-text-centered" />

          <div class="column is-10 pt-0">
            <div class="columns">
              { pixieBox(hbRef1, "We deploy a support team to your project") }

              { pixieBox(hbRef2, "Your data pool environment is up and ready to be used") }

              { pixieBox(hbRef3, "We offer support with data integration and analysis") }

              { pixieBox(hbRef4, "We provide a communicable brochure about your data pool") }


            </div>
          </div>
          <div class="column is-1 has-text-centered" />
        </div>

        <br />

      </div>
    </section>
  )
}

export default Breakdown;
