import React, { Component } from 'react';

import Section from 'components/Section';

class DataSynthLayout extends Component {

  render() {
    return (
      <Section>
        <div class="container">
          <div>
          </div>
        </div>
      </Section>
    );
  }
}

export default DataSynthLayout;

