import React, { FC } from 'react';

const YellowCTA: FC = (props) => {
  return (
    <section class="section-primary-light">
      <div class="container">
        <h1 class="title is-size-2 has-text-centered py-5">
          We are scaling our services. We want to make data pools easy for everyone. Check out our new product in development
        </h1>
      </div>

      <br /> <br /> <br />

      <section class="container is-max-desktop">
        <div class="columns is-mobile">
          <div class="column is-variable is-offset-8-desktop is-4-desktop is-offset-6-touch is-6-touch">
            <a href="https://datagarden.app">
              <div class="buttons is-right pt-5">
                <button class="button is-medium is-fullwidth is-primary">
                  Get started
                </button>
              </div>
            </a>
          </div>
        </div>
      </section>

      <br /> <br /> <br />
    </section>
  )
}

export default YellowCTA;
