import React, { Component } from 'react';
import { Route } from "react-router-dom";

import Section from 'components/Section';
import Navbar from 'components/Navbar';
import Blockquote from 'components/Blockquote';

import avatar1 from 'assets/avatar1.svg';
import avatar2 from 'assets/avatar2.svg';


class AboutRoute extends Component {
  parentPath = this.props.match.path;

  render() {
    return (
      <div>
        <Navbar transparent={false} />

        <Route path={this.parentPath} component={About} />
      </div>
    )
  }
}


class About extends Component {

  render() {
    return (
      <Section backdrop={true}>
        <div class="container">
          <div class="anchor" id="about"></div>
          <div class="columns is-centered is-vcentered px-7" id="no-px-mobile">

            <div class="column pl-6 has-text-justified has-text-left-mobile" id="no-px-mobile">
              <h1 class="title is-2 has-text-left">
                About PixelCities
              </h1>
              <br />

              <h2 class="subtitle is-4 is-size-5-mobile">
                <div class="block">
                  We aim to power public sector decision making concerning sustainable development through the aggregation of all relevant knowledge across multi stakeholder data pools,
                  particularly in secondary and smaller cities who would otherwise not be able to leverage efficiencies arising out of digital technology.
                </div>
                <div class="block">
                  Our vision is that knowledge concerning the sustainable development of the planet and society is accessible to decision makers at every scale.
                </div>
              </h2>
              <br /><br />
            </div>

          </div>
        </div>

        <Blockquote anchor="avatar2" image={avatar2} invert={true}>
          <p>
            Governments, research organisations or businesses of any size wrangle and process datasets in silos either through their own departments or through consultants.
            Others who don’t have access to such services and infrastructure are not able to inform their decisions adequately. We hope to empower those who would
            otherwise be unable to utilise public and privately held data in a reliable manner.
          </p>

          <p class="cite">
            Marijana, CEO & Co-Founder
          </p>
        </Blockquote>

        <Blockquote anchor="avatar1" image={avatar1}>
          <p>
            Security and privacy frequently have to take a back seat for more prominent issues in an organisation.
            Even when aware that current practices may pose a problem, it is often difficult to know where to even begin due to unknown unknowns.
            Working with data is amazing, but why would you have to know about all the infrastructure around it just to catch possible security and privacy issues.
            After encountering organisations taking the lax approach one to many times, we decided to do something about it.
          </p>

          <p class="cite">
            Mathijs, CTO & Co-Founder
          </p>
        </Blockquote>

      </Section>
    );
  }
}

export default AboutRoute;
